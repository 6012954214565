import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';

import { imageLookup } from '../utilities/imageLookup';

const AboutPage = () => {
	React.useEffect(() => window.scrollTo(0, 0), []);

	return (
		<Layout>
			<SEO
				pageTitle="About Us"
				description="We built Embarkable to help get more meaningful feedback so you can build better products."
			/>

			<div className="">
				<section className="">
					<div className="flex flex-col justify-center w-full pt-32 mx-auto font-light text-center text-gray-800 lg:w-3/5">
						<h1 className="my-6 text-5xl font-semibold text-red-400">Our story</h1>
						<p className="w-full px-4 mx-auto text-sm leading-relaxed text-center xl:text-xl">
							Back in 2014 we started an IOT (internet-of-things) company through a
							Kickstarter campaign. We had enthusiastic customers and lots of ideas,
							but no great way of proiritizing which features to build. We implemented
							a simple Excel sheet, which later changed to a Trello board, but neither
							was a great solution. After investigating product management solutions,
							we found they were built for large enterprise business's with teams of
							dedicated product managers. We wanted a lean yet effective
							prioritization and validation tool, and Embarkable was born.
						</p>
						<h3 className="my-6 text-2xl font-semibold text-red-400">
							We hope you find Embarkable as useful as we do.
						</h3>
					</div>
				</section>

				<section className="py-32">
					<div className="flex flex-col justify-center w-4/5 mx-auto text-gray-700">
						<div className="justify-center w-64 h-64 mx-auto overflow-hidden rounded-full">
							<img
								className="bg-cover"
								src={imageLookup.natelily}
								alt="Embarkable Team"
							/>
						</div>
						<div className="mx-auto text-center">Nathan & Lilyann</div>
					</div>
				</section>
			</div>
		</Layout>
	);
};

export default AboutPage;
